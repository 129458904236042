@import "src/css/variables";

html.rpst-portal.aui .help-page {
  background-color: #fff;
  max-width: 800px;
  margin: 0 auto;
  //    padding-top: 35px;
  padding-bottom: 49px;
  color: $text-color-base;

  ul, ol {
    padding: 0;
    margin: 0 0 10px 25px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    width: 100%;
    padding-bottom: 7px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 15px;
  }

  header {
    h1 {
      font-size: 32px;
      font-weight: 300;
      margin-top: -8px;
      text-align: center;
      margin-bottom: 28px;
    }

    p {
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      line-height: 24px;
      margin: 0 80px;
    }

    b {
      white-space: nowrap;
      font-weight: 500;
      color: #202020;
    }

    a {
      font-weight: normal;
    }
  }

  article {
    font-size: 18px;
    line-height: 24px;
    margin-top: 40px;
    font-weight: normal;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
    }

    h1 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 15px;
      padding-bottom: 7px;
    }

    h2 {
      font-size: 20px;
      line-height: 24px;
      margin: 24px 0 12px;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin: 6px 0 12px;
    }

    p {
      margin: 6px 0 0 0;
    }

    ul, ol {
      margin: 5px 0 0 0;
    }

    ol ul {
      list-style-type: disc;
    }

    li {
      margin-top: 2px;
      margin-left: 1.4em;
    }

    a {
      font-weight: normal;
    }

    table {
      width: 100.0%;
      border-spacing: 7px 5px;
      border: 1px solid $grey-dark;

      th {
        color: #A9A9A9;
        font-weight: normal;
        text-align: left;
      }

      td, th {
        padding: 10px;
        border: 1px solid $grey-medium;
        height: 26px;

        &.vertical-align-top {
          vertical-align: top;
        }
      }

      &.no-border {
        border: none;

        td, th {
          border: none;
        }
      }
    }
  }

  footer {
    margin-top: 60px;
    padding-top: 40px;
    color: #aaa;
    position: relative;

    &:before {
      content: '';
      width: 240px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 10px;
      border-top: 2px solid #e1e1e1;
    }

    p {
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      line-height: 23px;
      margin: 0 80px;
    }

    b {
      white-space: nowrap;
      font-weight: normal;
      font-size: 22px;
      letter-spacing: 0;
      color: #202020;
    }

    a {
      font-weight: 500;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 15px;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      word-spacing: 5px;
    }
  }
}
