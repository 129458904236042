@import "src/css/variables";

html.rpst-portal.aui .tracking-setting {
  width: 450px;
  margin: 0 auto;

  &__label {
    margin-top: 31px;
    color: $grey-dark;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
  }

  &__value {
    font-size: 18px;
    font-weight: 400;
    color: $grey-dark;

    &--bold {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__desc {
    color: #999999;
  }

  &__send, &__save {
    display: inline-block;
    margin-top: 29px;
    margin-right: 17px;
    padding-left: 28px;
    padding-right: 28px;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
  }

  &__reset-password, &__disable {
    color: #E84132;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
  }
}
