html.rpst-portal.aui {
  .error-404-page:before {
    height: 110px;
    background-image: url('../../img/404.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
  }

  .error-500-page:before {
    height: 120px;
    background-image: url('../../img/500.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
  }

  .error-page {
    display: inline-block;
    vertical-align: middle;
    position: relative;


    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      padding-bottom: 24px;
      width: 100%;
      left: 0;
    }

    &-container {
      position: absolute;
      text-align: center;
      left: 0;
      top: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      z-index: 1;
      background-color: #f7f7f7;

      &:before {
        content: '';
        display: block;
        position: absolute;
        //bottom: 96px;
        width: 100%;
        left: 0;
        height: 1px;
        background: #e6e6e6;
      }

      &:after {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
        content: '';
      }
    }

    &__title {
      font-size: 22px;
      line-height: 24px;
      color: black;
    }

    &__description {
      font-size: 18px;
      line-height: 24px;
      margin: 12px auto 0;
      color: #808080;
      width: 450px;
    }
  }

  .service-error {
    color: #424242;
    text-align: center;
    padding: 1px 1px 100px;


    &-page {
      background-color: #fff;
    }

    &__status {
      font-size: 90px;
      line-height: 100px;
      font-weight: 100;
      margin-top: 73px;
    }

    &__message {
      font-size: 39px;
      margin-top: 10px;
      margin-bottom: 46px;
    }

    &__description {
      font-size: 18px;
      line-height: 23px;
    }

    &__link {
      text-decoration: none;
      font-size: 18px;
      color: #0055A6;
    }
  }
}
