@import "src/css/variables";

html.rpst-portal.aui .header-container {
  width: 100%;
  background: white;
  position: relative;
  opacity: 1;
  transition: opacity 500ms ease-out;
  z-index: 2;

  &:empty {
    border-bottom-color: transparent;
  }

  &--hidden {
    opacity: 0;
    transition: none;
  }

  .page-container {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 766.95px) and (min-width: 0px) {
      max-width: 100%;
      padding: 0 12px;
    }

    @media (max-width: 1022.95px) and (min-width: 768px) {
      max-width: 100%;
      padding: 0 16px;
    }

    @media (max-width: 1364.95px) and (min-width: 1024px) {
      max-width: 1000px;
    }

    @media (max-width: 1918.95px) and (min-width: 1366px) {
      max-width: 1204px;
    }

    @media (min-width: 1920px) {
      max-width: 1672px;
    }

    @media (min-width: 1024px) {
      padding: 0 20px;
    }
  }

  .box-line {
    border-bottom: 1px solid #e6e7ed
  }

  .link-styled {
    cursor: pointer;
    border-bottom: .4px solid rgba(18, 40, 230, .2);
    font-size: 16px;
    line-height: 20px;
    color: #1228e6;
    border-top-color: rgba(18, 40, 230, .2);
    border-right-color: rgba(18, 40, 230, .2);
    border-left-color: rgba(18, 40, 230, .2);
    text-decoration: none !important;

    &:visited {
      color: #7140a2;
      border-color: rgba(113, 64, 162, .2);
    }

    &:hover {
      color: #ff5a00;
      border-color: rgba(255, 90, 0, .2);
    }
  }
}
