@import "src/css/variables";

html.rpst-portal.aui .landing-page {
  background: white;
  box-shadow: inset 0 1px 0 0 #E6E6E6;
  position: relative;

  &__header {
    padding: 30px 0;
    width: 100%;
    left: 0;
  }

  &__content {
    width: 960px;
    min-height: 150px;
    margin: 33px auto $footer-height auto;

    & :first-child {
      margin-left: 0;
    }

    & :last-child {
      margin-right: 0;
    }
  }

}
